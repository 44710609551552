import "./static/css/app.style.css";
import { SideBar } from "./components/SideBar";
import { Intro } from "./components/Intro";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { About } from "./components/About";
import { Experience } from "./components/experience/Experience";
import { Projects } from "./components/projects/Projects";
import { Footer } from "./components/Footer";
import { Contact } from "./components/Contact";

import { useEffect } from "react";
import { initializeTagManager } from "./utils/gtm";
const App = () => {
  useEffect(() => {
    initializeTagManager();
  }, []);
  return (
    
    <div className="App">
      <div className="container-fluid row">
        <div className="offset-md-2 col-md-8">
          <div className="row">
            {" "}
            <Intro />
          </div>
          <div className="row">
            {" "}
            <About />
          </div>

          <div className="row">
            <Experience />
          </div>
          <div className="row">
            {" "}
            <Projects />
          </div>
          <div className="row">
            {" "}
            <Contact />
          </div>
        </div>
        <div className="d-none d-lg-block col-md-2">
          <SideBar
            intro="intro"
            about="about"
            experience="experience"
            projects="projects"
            contact="contact"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;