import React from "react";
import "../../static/css/experience.style.css";
import Timeline from "./Timeline";

const experiences = [
  {
    id: "1",
    abbr: "OBS",
    fullEntrepriseTitle: "Orange Business",
    title: "Software Engineering Intern ",
    link: "https://www.linkedin.com/company/orange-business-services-maroc",
    date: "FEB 2024 - AUG 2024",
    description:
      'An application called "AstreintEase" aimed at simplifying the validation and approval process of on-call requests within the company.',
    outils:
      "Skills: Spring Framework · Angular · MySQL · Spring Boot · Java · Spring CLOUD · Scrum · Mircoservices",
    active: true,
  },
  {
    id: "2",
    abbr: "OBS",
    fullEntrepriseTitle: "Orange Business",
    title: "Software Engineering Intern ",
    link: "https://www.linkedin.com/company/orange-business-services-maroc",
    date: "JULY 2023 - SEPT 2023",
    description:
      'The development of an application called "Orange Staffing" that automates internal recruitment management.',
    outils:
      "Skills: Spring Framework · Angular · MySQL · Spring Boot · Java · Web Development · Scrum",
    active: false,
  },
  {
    id: 3,
    abbr: "CEGEDIM",
    fullEntrepriseTitle: "CEGEDIM",
    title: "Software Engineering Intern ",
    link: "https://www.linkedin.com/company/cegedim/",
    date: "JULY 2022 - SEPT 2022",
    description:
      'I designed and built a web application "Poker Planning" to enable SCRUM team members to collaborate and estimate the relative size or effort required for user stories or tasks.',
    outils:
      "Skills: Spring Framework · Angular · MySQL · Spring Boot · Java · Web Development · Scrum",
    active: false,
  },
  {
    id: 4,
    abbr: "MEN",
    fullEntrepriseTitle: "Direction Provenciale de l'éducation nationale",
    title: "Software Engineering Intern ",
    link: "https://www.men.gov.ma/",
    date: "MARS 2021 - MAY 2021",
    description:
      'The design and development of a web application called "Tawajooh" that facilitates the management of applications from high school students.',
    outils:
      "Skills: HTML · CSS · JavaScript · Flask · Firebase RealTime Database",
    active: false,
  },
];

export function Experience() {
  return (
    <div id="experience" className="experience-wrapper">
      <Timeline experiences={experiences} />
    </div>
  );
}
