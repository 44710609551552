import React from "react";
import "../../static/css/resume-download-button.style.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResumeDownloadButton = () => {
  return (
    <>
      <a
        href="/EL_HANAFI_Resume.pdf"
        download="EL_HANAFI_Resume.pdf"
        className="btn-resume d-inline-flex align-items-center shadow-sm"
      >
        <FontAwesomeIcon
          icon={faDownload}
          className="fa-download download-icon"
        />

        <span className="ms-3 fw-semibold">Download Resume</span>
      </a>
    </>
  );
};

export default ResumeDownloadButton;
