import React, { useEffect } from "react";

import "../static/css/textsphere.style.css";

import TagCloud from "TagCloud";

const TextShpere = ({ texts }) => {
  useEffect(() => {
      const container = ".tagcloud";

      const options = {
        radius: 300,
        maxSpeed: "normal",
        initSpeed: "normal",
        keep: true,
      };

      TagCloud(container, texts, options);
  }, []);

  return (
    <div>
      <div className="text-shpere">
        <span className="tagcloud"></span>
      </div>
    </div>
  );
};

export default TextShpere;
