import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import "../../static/css/timeline.style.css";

const TimelineItem = ({ data, isActive }) => (
  <div className={`timeline-item ${isActive ? "active" : ""}`}>
    <div className={`timeline-dot ${isActive ? "active-dot" : ""}`}></div>
    <div className="timeline-content">
      <div className="timeline-date">{data.date}</div>
      <h3 className="timeline-title">{data.fullEntrepriseTitle}</h3>
      <h4 className="timeline-subtitle">{data.title}</h4>
      <div className="timeline-details">
        <p className="timeline-description">{data.description}</p>
        <p className="timeline-skills">{data.outils}</p>
      </div>
    </div>
  </div>
);

const Timeline = ({ experiences }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const timelineRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (timelineRef.current) {
        const { top, height } = timelineRef.current.getBoundingClientRect();
        const scrollPosition = window.innerHeight / 2;

        const index = Math.floor(
          (scrollPosition - top) / (height / experiences.length)
        );
        setActiveIndex(Math.max(0, Math.min(index, experiences.length - 1)));
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [experiences.length]);

  return (
    <Container className="timeline-container" ref={timelineRef}>
      <h2 className="section-title">/experience</h2>
      <div className="timeline">
        <div className="timeline-line">
          <div
            className="timeline-scroll-dot d-none"
            style={{
              top: `${(activeIndex / (experiences.length - 1)) * 100}%`,
            }}
          />
        </div>
        {experiences.map((exp, index) => (
          <TimelineItem
            key={exp.id}
            data={exp}
            isActive={index === activeIndex}
          />
        ))}
      </div>
    </Container>
  );
};

export default Timeline;
