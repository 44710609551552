import React from "react";
import "../static/css/intro.style.css";
import myImage from "../static/images/img.jpg";
import ResumeDownloadButton from "./ui/ResumeDownloadButton";

export function Intro() {
  return (
    <div id="intro" className="mb-3 intro-wrapper container">
      <div className="intro-paragraph" style={{ marginTop: 10 }}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="intro-title">
              Hi,<span className="intro-name"> EL HANAFI </span> here.
            </div>
            <h4 className="intro-name" style={{ paddingTop: 10 }}>
              Full Stack Developer
            </h4>
            <p style={{ paddingTop: 10 }}>
              Welcome! I'm EL HANAFI, a dedicated software engineer based in
              Marrakech. With a deep passion for coding and a wealth of
              experience in software development, I thrive on creating
              innovative solutions that make a difference. Let's connect and
              explore how we can collaborate to bring your ideas to life.
            </p>
            <ResumeDownloadButton />
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <img className="intro-img img-fluid" src={myImage} alt="profile" />
          </div>
        </div>
      </div>
    </div>
  );
}
