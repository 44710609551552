import React from "react";
import "../static/css/about.style.css";
import TextShpere from "./TextSphere";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot } from "@fortawesome/free-solid-svg-icons/faMugHot";
import { faGamepad } from "@fortawesome/free-solid-svg-icons/faGamepad";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons/faWindowRestore";

export function About() {
  const technologies = [
    "HTML",
    "CSS",
    "Bootstrap",
    "JavaScript",
    "Spring Boot",
    "Spring Cloud",
    "Spring Core",
    "Spring MVC",
    "Spring Data JPA (Hibernate ORM)",
    "Angular",
    "React",
    "Flask",
    "Java",
    "C++",
    "Python",
    "Oracle",
    "MySQL",
    "Firebase RealTime Database",
    "MongoDB",
    "Redis",
    "Maven",
    "SCRUM",
  ];

  return (
    <div id="about" className="about-wrapper container">
      <h3 className="title">/about</h3>
      <br />
      <div className="paragraph" style={{ paddingTop: 10 }}>
        <p>
          Junior Software Engineer with a passion for programming and
          problem-solving. Skilled in full-stack development using Spring and
          Angular. Experienced in contributing to significant projects at
          renowned companies, continually refining my expertise in software
          engineering.{" "}
        </p>
        <p>Here are some fun facts about me :</p>
        <ul>
          <li><FontAwesomeIcon icon={faLightbulb} className="fa-solid fa-lightbulb" style={{color: "#e0aa17",}}/> I Learn anything my hands get on</li>
          <li><FontAwesomeIcon icon={faGamepad} className="fa-solid fa-gamepad" style={{color: "#e0aa17",}}/> I Play video games for fun</li>
          <li><FontAwesomeIcon icon={faMugHot} className="fa-solid fa-mug-hot" style={{color: "#e0aa17",}}/> I Refuse to code without drinking my green tea</li>
          <li><FontAwesomeIcon icon={faWindowRestore} className="fa-solid fa-window-restore" style={{color: "#e0aa17",}} /> I Have 50 internet tabs open at any given time</li>
        </ul>
        <p>Here are some technologies I have been working with:</p>
        <TextShpere texts={technologies} />
      </div>
    </div>
  );
}
