import React from "react";
import "../static/css/contact.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export function Contact() {
  return (
    <div id="contact" className="contact-wrapper container">
      <h3 className="title">/contact</h3>
      <br />
      <div className="row">
        <div className="col-md-6">
          <h2>Let's Get in Touch</h2>
          <p className="card-text mt-3">
            I enjoy discussing new projects and design challenges. Please share
            as much info as possible so we can get the most out of our first
            catch-up.
          </p>
          <ul className="card-text">
            <li className="pt-1 pb-1">
              <strong>Living In:</strong> Marrakech, MA.
            </li>
            <li className="pt-1 pb-1">
              <strong>Email:</strong> elhanafiyassine21@gmail.com
            </li>
            <li className="pt-1 pb-1">
              <strong>Call:</strong> +212 708161260
            </li>
          </ul>
          <div className="social-icons">
            <a href="https://github.com/yassinehanafi3">
              <FontAwesomeIcon icon={faGithub} className="fa fa-github" />
            </a>{" "}
            <a href="https://www.linkedin.com/in/elhanafiyassine/">
            <FontAwesomeIcon icon={faLinkedin} className="fa fa-linkedin" />
          </a>
          </div>
        </div>
        <div className="col-md-6">
          <h2>Send Me an Email</h2>
          <form className="mt-3">
            <div className="form-group mb-2">
              <label className="mb-1">Your Name:</label>
              <input
                type="text"
                className="input"
              />
            </div>
            <div className="form-group mb-2">
              <label className="mb-1">Your Email:</label>
              <input
                type="email"
                className="input"
              />
            </div>
            <div className="form-group mb-3">
              <label className="mb-1">Your Message:</label>
              <textarea
                className="input"
                rows="4"
              ></textarea>
            </div>
            <button
              type="submit"
              className="float-end contact-button mt-1 mb-3"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
